import {Box, Card, Table, TableBody, TableHead, TableRow, Tooltip, Typography} from '@mui/material';
import {observer} from "mobx-react";
import {Fragment, useEffect, useRef} from 'react';
import {useTranslation} from "react-i18next";
import {
    AddButton,
    AddLocalityButton,
    AuthorizePermission,
    Cell,
    EmptyBox,
    ExpandButton,
    ExpandRow,
    ExpertDialog,
    HCell,
    Pagination,
    Progress,
    ResetFiltersButton,
    Search
} from "../../components";
import {CopyToClipboard} from "../../components/CopyToClipboard";
import {LocalityFilter} from "../../components/select/Locality/LocalityFilter";
import {DefaultMultiSelect} from "../../components/shared/components/DefaultMultiSelect";
import {CloudUpload as CloudUploadIcon} from '../../icons/cloud-upload';
import {useManagementStore} from "../../stores";
import {Details} from "./Details";

import {MenuComponent} from "../../components/MenuComponent";
import {MenuItemComponent} from "../../components/MenuItemComponent";
import {NavLink} from "../../components/NavLink";
import {TabTitle} from "../../components/TabTitle";
import {FiltersComponent} from "../../components/filters/FiltersComponent";
import {SeverityPill, SeverityPillColor} from "../../components/severity-pill";
import {DefaultSelect} from "../../components/shared/components/DefaultSelect";
import {AllPermissions} from "../../core/const";
import {PersonCreator} from "./creator/PersonCreator";

export const personStatusHandler = (status: string) => {
    if (status === 'Не работает' || status === 'На пенсии' || status === 'Покинул страну' || status === 'Декрет') {
        return 'warning'
    } else if (status === 'Требует дополнительной валидации' || status === 'Не удалось уточнить') {
        return 'info'
    } else if (status === 'МДМ-репчеки' || status === 'Можно посетить') {
        return 'secondary'
    } else if (status === 'Умер' || status === 'Отклонено' || status === 'Дубль' || status === 'Ошибочно добавлено') {
        return 'error'
    } else if (status === 'Нет активных мест работы' || status === 'Взято из Дубля') {
        return 'primary'
    } else {
        return 'success'
    }
}

export const Persons = observer(() => {
    const { personStore } = useManagementStore();
    const { t } = useTranslation();

    const tableTopRef = useRef<null | HTMLDivElement>(null)

    const scrollToTop = () => {
        tableTopRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToTop()
    }, [personStore.value]);

    return (
        <AuthorizePermission permissions={AllPermissions.person.read} showWarning>
            <ExpertDialog
                title={t('create.person')}
                open={personStore.creator.Open}
                onClose={() => personStore.creator.Open = false}
            >
                <PersonCreator store={personStore.creator}/>
            </ExpertDialog>
            <TabTitle title={t('pageNames.persons')}>
                <AuthorizePermission permissions={AllPermissions.person.create}>
                    <AddButton onClick={() => personStore.creator.Open = true} testid="persons-create-btn" />
                </AuthorizePermission>
            </TabTitle>
            <FiltersComponent>
                <Box sx={{ mt: 1 }}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Search
                        autoFocus
                        value={personStore.search.value}
                        onChange={t => personStore.search.value = t}
                        placeholder={t('common.searchFullname')}
                        style={{ width: '100%' }}
                        testid="persons-fio"
                    />
                </Box>
                <Box sx={{ mt: 2 }}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Search
                        value={personStore.searchById.value}
                        onChange={t => personStore.searchById.value = t}
                        placeholder='Id'
                        style={{ width: '32%' }}
                        testid="persons-id"
                    />
                    <Search
                        value={personStore.searchByExternalId.value}
                        onChange={t => personStore.searchByExternalId.value = t}
                        placeholder='External Id'
                        style={{ width: '32%' }}
                        testid="persons-externalId"
                    />
                    <Search
                        value={personStore.searchByAntidoubleId.value}
                        onChange={t => personStore.searchByAntidoubleId.value = t}
                        placeholder='Antidouble Id'
                        style={{ width: '32%' }}
                        testid="persons-antidoubleId"
                    />
                </Box>
                <Box
                    sx={{ mt: 2 }}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Search
                        placeholder={t('common.searchSurname')}
                        value={personStore.surnameFilter.value!}
                        onChange={t => personStore.surnameFilter.value = t}
                        style={{width: '24%'}}
                        testid="persons-surname"
                    />

                    <Search
                        placeholder={t('common.searchName')}
                        value={personStore.nameFilter.value!}
                        onChange={t => personStore.nameFilter.value = t}
                        style={{width: '24%'}}
                        testid="persons-name"
                    />

                    <Search
                        placeholder={t('common.searchMiddleName')}
                        value={personStore.middleNameFilter.value!}
                        onChange={t => personStore.middleNameFilter.value = t}
                        style={{width: '24%'}}
                        testid="persons-middleName"
                    />

                    <Search
                        placeholder={t('common.searchMidenname')}
                        value={personStore.midenNameFilter.value!}
                        onChange={t => personStore.midenNameFilter.value = t}
                        style={{width: '24%'}}
                    />
                </Box>
                <Box
                    sx={{
                        mt: 0,
                        mb: 0,
                    }}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <DefaultMultiSelect store={personStore.statusesFilter}
                        title={t('common.status')}
                        style={{ width: '32%' }}
                        nameExp={(dto) => dto.nameRus}
                        testid="persons-statusesFilter"
                    />
                    <DefaultSelect store={personStore.statusJobsFilter}
                        hasLabel
                        style={{ width: '32%', minWidth: 250, marginTop: 4 }}
                        testid="persons-genderFilter"
                        title={'Статус Рабочего места'}
                    />
                    <DefaultMultiSelect store={personStore.postsFilter}
                        title={t('common.post')}
                        style={{ width: '32%' }}
                        testid="persons-postsFilter"
                    />
                </Box>
                <Box
                    sx={{
                        mt: -1,
                        mb: 0,
                    }}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <DefaultMultiSelect store={personStore.specialityFilter}
                        title={t('common.speciality')}
                        style={{ width: '32%' }}
                        testid="persons-specialityFilter"
                    />
                    <DefaultSelect store={personStore.genderFilter}
                        hasLabel
                        style={{ width: '32%', minWidth: 250, marginTop: 4 }}
                        testid="persons-genderFilter"
                        title={t('common.gender')}
                    />
                    <DefaultMultiSelect store={personStore.tagsFilter}
                        title={t('common.tags')}
                        style={{ width: '32%' }}
                        testid="persons-tagsFilter"
                    />
                </Box>
                <Box style={{ display: 'flex', height: 'auto' }} sx={{ my: 1 }}>
                    <ResetFiltersButton onClick={() => personStore.resetFilters()} testid="persons-resetFilter-btn" />
                    <LocalityFilter store={personStore.localitiesFilter} style={{ flex: 1, marginLeft: 40 }} />
                    <AddLocalityButton onClick={() => personStore.localitiesFilter.open()}
                        testid="persons-addLocality-btn" />
                </Box>
            </FiltersComponent>
            <Card sx={{ mb: 8, overflow: 'unset' }}>
                <Table sx={{ minWidth: 1250 }}>
                    <TableHead style={{ position: 'sticky', top: 63, zIndex: 999 }}>
                        <TableRow>
                            <HCell width='3%' testid="persons-expand"></HCell>
                            <HCell orderBy='id' store={personStore} style={{ width: 130 }}
                                testid="persons-id">Id</HCell>
                            <HCell orderBy='externalId' style={{ width: 115 }}
                                store={personStore} testid="persons-externalId">ExternalId</HCell>
                            <HCell orderBy='surname' store={personStore}
                                style={{ width: 140 }} testid="persons-surname">{t('table.surname')}</HCell>
                            <HCell orderBy='name' store={personStore}
                                style={{ width: 130 }} testid="persons-name">{t('table.name')}</HCell>
                            <HCell orderBy='middleName' store={personStore}
                                style={{ width: 130 }} testid="persons-middleName">{t('table.middleName')}</HCell>
                            <HCell orderBy='gender' store={personStore}
                                style={{ width: 90 }} testid="persons-gender">{t('table.gender')}</HCell>
                            <HCell orderBy='personStatus' store={personStore}
                                style={{ width: 120 }} testid="persons-status">{t('table.status')}</HCell>
                            <HCell store={personStore} style={{ width: 180 }} testid="persons-tags">{t('common.tags')}</HCell>
                            <HCell orderBy='modifiedAt' store={personStore} styleText={{ whiteSpace: 'nowrap' }}
                                style={{ width: 160 }} testid="persons-modifiedAt">{t('table.date')}</HCell>
                            <HCell store={personStore} testid="persons-action"
                                style={{ width: 30, padding: '0 8px 0 0' }}> {t('table.action')}</HCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {personStore.items.length > 0 && personStore.items.map((person) => {
                            const open = personStore.details.has(person.id);
                            return (
                                <Fragment key={person.id}>
                                    <TableRow
                                        hover
                                        key={person.id}
                                    >
                                        <ExpandButton item={person} isOpen={open} testid="persons"
                                            onClick={() => {
                                                if (!!open) {
                                                    personStore.closeDetails(person.id)
                                                } else {
                                                    personStore.openDetails(person.id);
                                                    person.loadDobles();
                                                    person.notes.countNotes(person.id).then(x => person.countNotes = x)
                                                }
                                            }} />
                                        <Cell style={{ paddingRight: 10, marginRight: 10 }} testid="persons-id">
                                            <Typography variant="subtitle2"
                                                style={{ display: "flex", alignItems: 'center' }}>
                                                {`${person.id.substring(0, 11)}...`}
                                                <CopyToClipboard copyText={person.id} />
                                            </Typography>

                                        </Cell>
                                        <Cell style={{ width: 100 }} testid="persons-externalId">
                                            <Typography
                                                color="textSecondary"
                                                variant="subtitle2"
                                                style={{ alignItems: 'center', display: "flex", width: 100 }}
                                            >
                                                {person.externalId}
                                                <CopyToClipboard copyText={person.externalId} />
                                            </Typography>
                                        </Cell>
                                        <Cell style={{ maxWidth: 150 }} testid="persons-surname">
                                            <NavLink to={`${person.id}`}>
                                                <Typography variant="subtitle2" sx={{
                                                    maxWidth: 150,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}>
                                                    {person._dto?.surname?.nameRus}
                                                </Typography>
                                            </NavLink>

                                        </Cell>
                                        <Cell
                                            style={{ maxWidth: 150 }}
                                            testid="persons-name"
                                        >
                                            <NavLink to={`${person.id}`}>
                                                <Typography variant="subtitle2" sx={{
                                                    maxWidth: 150,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                                >
                                                    {person._dto?.firstName?.nameRus}
                                                </Typography>
                                            </NavLink>
                                        </Cell>
                                        <Cell
                                            style={{ maxWidth: 150 }}
                                            testid="persons-middleName"
                                        >
                                            <NavLink to={`${person.id}`}>
                                                <Typography variant="subtitle2" sx={{
                                                    maxWidth: 150,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}>
                                                    {person._dto?.middleName?.nameRus}
                                                </Typography>
                                            </NavLink>
                                        </Cell>
                                        <Cell style={{ maxWidth: 95 }} testid="persons-gender">
                                            <Typography variant="subtitle2" color="textSecondary" sx={{
                                                maxWidth: 80,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>
                                                {person._dto?.gender?.nameRus}
                                            </Typography>
                                        </Cell>
                                        <Cell style={{ width: 100 }} testid="persons-status">
                                            <SeverityPill
                                                color={personStatusHandler(person._dto.personStatus?.nameRus || '') as SeverityPillColor}>
                                                <Tooltip
                                                    title={person._dto.personStatus?.nameRus}>
                                                    <Typography variant="caption" align={'left'} sx={{
                                                        maxWidth: 160,
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                    }}>
                                                        {person._dto.personStatus?.nameRus}
                                                    </Typography>
                                                </Tooltip>
                                            </SeverityPill>
                                        </Cell>
                                        <Cell testid="persons-tags">
                                            <AuthorizePermission permissions={AllPermissions.person.tags.read}>
                                                <div style={{ display: 'flex' }}>

                                                    <Box sx={{
                                                        maxWidth: 180,
                                                        maxHeight: 48,
                                                        overflow: 'hidden',
                                                        py: 0.5
                                                    }}>

                                                        {!!person._dto.tags?.length && person._dto.tags?.map((tag) => (
                                                            <SeverityPill color={personStatusHandler(tag?.nameRus || '')} key={tag.id}>
                                                                <Tooltip
                                                                    title={tag?.nameRus}>
                                                                    <Typography variant="caption" align={'left'} sx={{
                                                                        maxWidth: 160,
                                                                        textOverflow: 'ellipsis',
                                                                        overflow: 'hidden',
                                                                    }}>
                                                                        {tag?.nameRus}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </SeverityPill>
                                                        ))}

                                                    </Box>
                                                    {!!person._dto.tags?.length && person._dto.tags?.length > 2 && (
                                                        <MenuComponent
                                                            icon={
                                                                <SeverityPill sx={{ cursor: 'pointer', my: 'auto', px: 0.5 }}>
                                                                    +{person._dto.tags?.length}
                                                                </SeverityPill>
                                                            }>
                                                            <Box sx={{ maxWidth: 'auto' }}>
                                                                {person._dto.tags?.length > 2 && person._dto.tags.map(item => (
                                                                    <SeverityPill sx={{ mx: 1 }} color={personStatusHandler(item?.nameRus || '')} key={item.id}>
                                                                        <Typography variant="caption" align={'left'}>
                                                                            {item?.nameRus}
                                                                        </Typography>
                                                                    </SeverityPill>
                                                                ))}
                                                            </Box>
                                                        </MenuComponent>
                                                    )}

                                                </div>
                                            </AuthorizePermission>
                                        </Cell>
                                        <Cell style={{ width: 160 }} testid="persons-modifiedAt">
                                            <Typography variant="subtitle2" color="textSecondary">
                                                {person.modifiedAt}
                                            </Typography>
                                        </Cell>
                                        <Cell align={'center'} testid="organizationTable-action">
                                            <MenuComponent>
                                                <MenuItemComponent text={t('table.updateTick')} icon={<CloudUploadIcon fontSize="small" />}
                                                    onClick={async () => await person.updateTick()} />
                                            </MenuComponent>
                                        </Cell>
                                    </TableRow>
                                    {open && (
                                        <ExpandRow key={`${person.id}_`} item={person} colSpan={11}>
                                            <Details
                                                person={person}
                                                style={{
                                                    margin: '0 40px'
                                                }}
                                            />
                                        </ExpandRow>)}
                                </Fragment>
                            );
                        })}

                    </TableBody>

                </Table>
                {!personStore.items.length && <EmptyBox />}

                {(personStore.items.length > 0 || !!personStore.count) && <Pagination store={personStore} />}

                {(!personStore.items.length || personStore.loading) && <Progress store={personStore} />}

            </Card>
        </AuthorizePermission>
    );
});
