import {observer} from "mobx-react";
import {CreateButtonsGroup, FormRoot, FormRow} from "../../../../components";
import {DefaultSelect} from "../../../../components/shared/components/DefaultSelect";
import React from "react";
import {useTranslation} from "react-i18next";
import {PhoneCategoryDto, PhoneStatusDto, PhoneTypeDto,} from "../../../../services/management";
import {DefaultValueStore} from "../../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../../../components/shared/DefaultSelectStore";
import {DefaultValue} from "../../../../components/shared/components/DefaultValue";
import {DefaultFormModalStore} from "../../../../components/shared/DefaultFormModalStore";

interface Props {
    store: DefaultFormModalStore<{
        phone: DefaultValueStore<string>,
        type: DefaultSelectStore<PhoneTypeDto>,
        status: DefaultSelectStore<PhoneStatusDto>,
        category: DefaultSelectStore<PhoneCategoryDto>,
        description: DefaultValueStore<string>,
    }>;
}

export const PersonPhoneCreator = observer(({store}: Props) => {
    const {t} = useTranslation();

    return (
        <FormRoot sx={{p: 2}}>
            <FormRow>
                <DefaultValue
                    autoFocus
                    testid="personPhoneCreator-phone"
                    style={{width: '100%'}}
                    store={store.fields.phone}
                    title={t('common.phone')}
                    disabled={store.processing}
                />
            </FormRow>
            <FormRow>
                <DefaultSelect store={store.fields.status}
                               title={t('common.status')}
                               testid="personPhoneCreator-status"
                />
            </FormRow>
            <FormRow>
                <DefaultSelect store={store.fields.type}
                               title={t('common.type')}
                               testid="personPhoneCreator-type"
                />
            </FormRow>
            <FormRow>
                <DefaultSelect store={store.fields.category}
                               title={t('common.category')}
                               testid="personPhoneCreator-category"
                />
            </FormRow>
            <FormRow style={{height: "max-content", marginBottom: 8}}>
                <DefaultValue
                    testid="organizationPhoneCreator-note"
                    style={{width: '100%'}}
                    store={store.fields.description}
                    title={t('common.note')}
                    disabled={store.processing}
                    multiline
                    InputProps={{
                        sx: {
                            width: '100%',
                            "&.MuiInputBase-root": {
                                height: '100%'
                            }
                        }
                    }}
                />
            </FormRow>
            <CreateButtonsGroup
                onCancel={() => store.Open = false}
                onSave={async () => await store.submit()}
                isCancelBtnDisabled={store.processing}
                isSaveBtnDisabled={store.processing || !store.valid}
                testid="personPhoneCreator"
            />
        </FormRoot>
    );
});
